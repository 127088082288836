import { Box, Heading } from "@chakra-ui/react"
import type { NextPage } from "next"
import Head from "next/head"

const Home: NextPage = () => {
  return (
    <Box textAlign={"center"}>
      <Head>
        <title>404 - page not found</title>
      </Head>

      <Heading as="h1" size="2xl">
        404 - Page not found.
      </Heading>

      <Heading as="h2" size="xl" my={"20"}>
        The page you’re trying to access doesn’t exist.
      </Heading>
    </Box>
  )
}

export default Home
